.btn-circle {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.btn-sm {
    height: 1.8rem;
    width: 1.8rem;
    font-size: 0.75rem;
  }
  &.btn-lg {
    height: 3.5rem;
    width: 3.5rem;
    font-size: 1.35rem;
  }
}

.btn-icon-split {
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
  .icon {
    background: fade-out($black, .85);
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
  }
  .text {
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
  }
  &.btn-sm {
    .icon {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
    .text {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
  }
  &.btn-lg {
    .icon {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
    .text {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
  }
}

@media(max-width: 1230px) {
  .table-content .btn-size {
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
  }
}

.btn.btn-default {
  background-color: #eee;
  border: 2px solid #ddd;
}

@media(max-width: 350px) {
  .events-title {
    font-size: 14px;
  }
}

.btn-icon-event {
  display: block;

  .icon.text-gray-600 {
    padding: 7px 13px;
    background-color: #e1e1e1;
    display: inline-block;
    border-radius: 6px;
  }

  .text {
    margin: 0 5px;
  }
}

.received_form:last-child {
  margin-bottom: 50px;
}
.chat-content {
    height: 100%;
    .scroll-chat {
        height: calc(100vh - 100px);
        overflow-Y: scroll;
        -webkit-overflow-scrolling: touch;

        @media(max-width: 992px) {
            height: calc(100vh - 510px);
        }
        @media(max-width: 576px) {
            height: calc(100vh - 300px);
        }

        &::-webkit-scrollbar {
            width: 10px;
            border-radius: 10px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #ececec;
            border-radius: 10px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #97aef1;
            border-radius: 10px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #6080e0;
            border-radius: 10px;
        }
    }
}

.questionario-content {
    .resultad-content {
        margin-top: 10px;
        color: #5c6ea3
    }

    .alt-content {
        font-size: 15px;
        display: inline;
    }

    .votes-content {
        display: inline;
        font-size: 15px;

        strong {
            color: #007f94;
            display: inline;
            margin-left: 5px;
        }
    }
    .question-content {
        font-weight: bold;
        font-size: 15px;
        margin-top: 15px;
    }
}
.timer-content {
    padding: 15px 20px 0px;
}

.disparadores-content {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 10px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;

    .btn {
        margin-right: 10px;
        @media(max-width: 514px) {
            margin: 0;
            width: 100%;
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }
}

.table.table-bordered .btn {
    width: 100%;
}

.received_msg, .received_form {
    p, form {
        background: #e4e8fb none repeat scroll 0 0;
        border-radius: 3px;
        color: #646464;
        font-size: 14px;
        margin: 0;
        padding: 10px 10px 10px 10px;
        width: 100%;
    }

    .title-name {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 1.0rem;
    }

    span{
        color: #747474;
        display: block;
        font-size: 10px;
        margin: 3px 0 0;
        margin-bottom: 10px;
    }
}
.card-login {
    margin: 20px 0;

    @media(max-width: 600px) {
        .title-login {
            font-size: 18px;
        }
    }
}

.logo-login {
    text-align: center;
    margin-bottom: 20px;
}

.content-progressbar {
    padding: 20px 10px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;

    .flex-vote-progress {
        display: flex;
        justify-content: space-between;
    
        .progress {
            height: 30px;
            background-color: #c9cbd1;
        }

        .progress-bar {
            font-weight: bold;
            font-size: 15px;
            overflow: initial;
        }
    
        .progress,
        .text-content {
            width: 50%;
            margin: 0;
        }

        @media(max-width: 1000px) {
            flex-direction: column;
            .progress,
            .text-content {
                width: 100%;
            }

            .text-content {
                margin-bottom: 10px;
            }
        }
    }
}

.research-content {
    margin-bottom: 15px;
}

.research-content:last-child {
    margin-bottom: 0;
}
.btn-download{
    color: white !important;
    cursor: pointer; 
}
.text-red{
    color: red;
}